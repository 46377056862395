import { Row } from 'components/Row';
import Spacing from 'components/Spacing';
import { Member } from 'components/Member';
import { IRecipient } from 'store/recipients/types';
import { MembersContainer, HiddenContainer } from './styled';
import { getRowLength } from 'helpers/utils';

export const MonthlyCandidates = ({ recipients }: { recipients: IRecipient[] }) => {
  const RowMembers = ({ list }: { list: IRecipient[] }) => (
    <Row $justifyContent="space-around">
      {list?.map((item: IRecipient, j: number) => (
        <Member
          key={j}
          isRecipient
          name={item.recipient_name || ''}
          title={item.race?.election.display_info || ''}
          recID={item.misc_ids?.photo_id}
          width="-webkit-fill-available"
        />
      ))}
    </Row>
  );

  return (
    <MembersContainer>
      <HiddenContainer $from={1100} $to={2000}>
        <RowMembers list={getRowLength(5, recipients)[0]} />
      </HiddenContainer>
      <HiddenContainer $from={800} $to={1099}>
        {getRowLength(3, recipients)?.map((list: IRecipient[], i: number) => (
          <div key={i}>
            <RowMembers list={list} />
            <Spacing $size={16} />
          </div>
        ))}
      </HiddenContainer>
      <HiddenContainer $from={0} $to={799}>
        {getRowLength(2, recipients)?.map((list: IRecipient[], i: number) => (
          <div key={i}>
            <RowMembers list={list} />
            <Spacing $size={16} />
          </div>
        ))}
      </HiddenContainer>
    </MembersContainer>
  );
};
