import { ENV_CONFIG } from 'config/environment';
import { Row } from 'components/Row';
import { Link } from 'components/Link';
import { BodySmall } from 'components/Typography';
import { getPathWithRef } from 'helpers/utils';
import {
  H2,
  LandingButton,
  ImpactReportSectionContainer,
  SectionContent,
  SectionImage,
  SectionImageContainer,
  SectionRow,
  SectionSubheader,
  ImpactCTA,
} from './styled';

const image = `${ENV_CONFIG().CLOUD_STORAGE.URL}/static_content/impact_report.png`;

export const ImpactReportSection = ({ period }) => {
  return (
    <ImpactReportSectionContainer>
      <Row $justifyContent="space-around">
        <SectionRow $justifyContent="space-between" $alignItems="center">
          <SectionImageContainer>
            <SectionImage src={image} alt="Oath Impact Report" />
          </SectionImageContainer>
          <SectionContent>
            <H2>2024 Impact Report</H2>
            {period === 'post-election' ? (
              <>
                <SectionSubheader>
                  Sign up and stay tuned for our Oath Impact Report to get insights on our 2024
                  accomplishments and learnings.
                </SectionSubheader>
                <LandingButton href={getPathWithRef('/login')}>Sign up now</LandingButton>
                <ImpactCTA>
                  <BodySmall>
                    Are you an Oath member? Subscribe for notifications for your personalized 2024
                    Impact Report by signing up for updates{' '}
                    <Link to={getPathWithRef('/login')}>here</Link>.
                  </BodySmall>
                </ImpactCTA>
              </>
            ) : (
              <>
                <SectionSubheader>
                  It’s been a year! Learn more about Oath’s annual impact and all of our 2024
                  accomplishments.
                </SectionSubheader>
                <LandingButton href={getPathWithRef('/profile')}>View now</LandingButton>
              </>
            )}
          </SectionContent>
        </SectionRow>
      </Row>
    </ImpactReportSectionContainer>
  );
};
