export const recipientIdsWithTags: string[] = [
  /*90, 105, 92, 359, 485, 1689, 335, 1688, 789*/
];
// TODO: update list above to use new recipient ids

export const getArePartnerTagsEnabled = (partner?: string) => {
  return !!partner && partner.slice(0, 3).toLowerCase() === 'taa';
};

export const getIsCandidateTagVisible = (recipientId: string, partner?: string) => {
  return getArePartnerTagsEnabled(partner) && recipientIdsWithTags.includes(recipientId);
};
