import { useEffect } from 'react';
import theme from 'config/theme';
import { ENV_CONFIG } from 'config/environment';
import { Link } from 'components/Link';
import Spacing from 'components/Spacing';
import { Footer } from 'components/Footer';
import { Bar } from 'components/Header/Bar';
import { EllipseText } from 'components/EllipseText';
import { Eyebrow, H4 } from 'components/Typography';
import { JoinCommunityCard } from 'components/Cards/JoinCommunityCard';
import { getPathWithRef, scrollToTop } from 'helpers/utils';
import {
  Container,
  EllipsesContainer,
  TopContainer,
  JoinCommunityContainer,
  TextsContainer,
  Title,
} from './styled';

export const DonorScreen = () => {
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <Bar />
      <TopContainer>
        <Container>
          <TextsContainer>
            <Eyebrow>What is Oath?</Eyebrow>
            <Spacing $size={16} />
            <Title>
              Oath is the one-stop-shop for political giving that helps YOU{' '}
              <Title $italic $color={theme.colors.oathBlue}>
                maximize your impact.
              </Title>
            </Title>
            <Spacing $size={16} />
            <H4>
              Every cycle, Democratic donors waste millions of dollars giving too much to long shot
              candidates, safe seats, or donating too late to make a difference.
            </H4>
          </TextsContainer>
          <Spacing $size={16} />
          <H4>Oath gives you a better option to:</H4>
          <EllipsesContainer>
            <EllipseText
              text="Find the most impactful Democratic, Independent, and Nonpartisan candidates to support"
              image={`${ENV_CONFIG().CLOUD_STORAGE.URL}/impact_score_page/donor_1.png`}
            />
            <EllipseText
              text="Drive results with lower processing fees (Oath doesn't take a cut!)"
              image={`${ENV_CONFIG().CLOUD_STORAGE.URL}/impact_score_page/donor_2.png`}
            />
            <EllipseText
              text="Stop the spam texts and emails. We promise no annoying texts or emails!"
              image={`${ENV_CONFIG().CLOUD_STORAGE.URL}/impact_score_page/donor_3.png`}
            />
          </EllipsesContainer>
        </Container>
      </TopContainer>
      <Container $lastContainer>
        <JoinCommunityContainer>
          <JoinCommunityCard
            title="Join our Oath community now!"
            cta="Join now"
            link={getPathWithRef('/login')}
          />
        </JoinCommunityContainer>
        <Spacing $size={60} />
        <TextsContainer>
          <Eyebrow>How We’re Different</Eyebrow>
          <Spacing $size={16} />
          <Title>
            Oath finds the issues, elections & candidates that you care about.{' '}
            <Title $italic $color={theme.colors.oathBlue}>
              We do the work for you!
            </Title>
          </Title>
          <Spacing $size={16} />
          <H4>Our easy-to-use platform allows you to:</H4>
        </TextsContainer>
        <EllipsesContainer>
          <EllipseText
            text="Direct your donations - choose to evenly split or customize donation amounts and candidates"
            image={`${ENV_CONFIG().CLOUD_STORAGE.URL}/impact_score_page/donor_4.png`}
          />
          <EllipseText
            text="Invest smarter - learn which candidates are running for offices that will impact legislation and rulings over the issues you care about"
            image={`${ENV_CONFIG().CLOUD_STORAGE.URL}/impact_score_page/donor_5.png`}
          />
          <EllipseText
            text="Avoid overfunded or long-shot races - utilize the Impact Score to understand where your dollars will have the highest ROI"
            image={`${ENV_CONFIG().CLOUD_STORAGE.URL}/impact_score_page/donor_6.png`}
          />
        </EllipsesContainer>
        <Spacing $size={20} />
        <TextsContainer>
          <H4>
            Political fundraising is broken. We all receive way too many spam texts and desperate
            emails without any clear indication of how donating will actually help. Oath is the
            solution for YOU - donors, activists, and everyday people who want to make a difference
            in our political system.
          </H4>
          <Spacing $size={16} />
          <H4>
            Please{' '}
            <Link to={getPathWithRef('/contact')} $fontSize="inherit">
              Contact Us
            </Link>{' '}
            with additional questions.
          </H4>
        </TextsContainer>
      </Container>
      <Footer />
    </>
  );
};
