import { useNavigate } from 'react-router-dom';
import { CandidateType } from 'components/Slate/Candidates/Candidates';
import {
  getPathWithRef,
  getUrlParams,
  getUrlSearchParamsObj,
  splitAmountRounded,
} from 'helpers/utils';
import {
  clearDonationSelections,
  getDonationSelections,
  setDonationSelections,
} from 'services/storage';
import { ProcessingRedirectOptions } from 'store/tags/constants';
import { useAppSelector } from 'helpers/hooks';
import { useEffect, useState } from 'react';
import { getAllTags } from 'selectors/tags';
import {
  getDonationSelectionsObj,
  getProcessingRedirect,
  saveDonationSelections,
} from 'helpers/donate';
import { useRedirectAfterLogin } from './useRedirectAfterLogin';

export const useOathCheckout = () => {
  const navigate = useNavigate();

  return (
    candidates: CandidateType[],
    amount: number,
    selectedTags?: string[],
    page?: string,
    issue?: string
  ) => {
    const splits = splitAmountRounded(amount, candidates.length);
    const useCustomSplits = Object.hasOwn(candidates[0], 'donationAmount');
    const donationSelections = {
      candidates: candidates.map((candidate, index) => ({
        ...candidate,
        donationAmount: useCustomSplits ? candidate.donationAmount : splits[index],
      })),
      total: amount.toString(),
    };

    // remove sessionStorage item if it was previously set and not removed
    clearDonationSelections();

    // convert donationSelection object to string so it can be stored in sessionStorage
    const donationSelectionsString = JSON.stringify(donationSelections);
    // save donationSelections to sessionStorage after user hits donate button on partner/issue/main page
    // and BEFORE user enters login/signup flow
    setDonationSelections(donationSelectionsString);

    const searchParamObj = getUrlSearchParamsObj();

    page && searchParamObj.set('p', page);
    issue && searchParamObj.set('issue', issue);

    let queryStr = searchParamObj.toString() || '';
    let navPath = '/checkout';

    if (selectedTags) {
      const tagsQueryStr = selectedTags.map(tag => `tags=${tag.replaceAll(' ', '%20')}`).join('&');

      queryStr += queryStr ? `&${tagsQueryStr}` : tagsQueryStr;
    }

    if (queryStr) {
      navPath += `?${queryStr}`;
    }

    navigate(getPathWithRef(navPath));
  };
};

export const useRedirectToCheckout = () => {
  const navigate = useNavigate();

  return () => {
    const donationSelections = getDonationSelections();
    if (!donationSelections) {
      return false;
    }
    const donationSelectionObj = JSON.parse(donationSelections);
    navigate(
      getPathWithRef(`${donationSelectionObj.pathname}?${donationSelectionObj.queryParams}`)
    );
    return true;
  };
};

export const useIsOathCheckoutEnabled = () => {
  const { urlPartner } = getUrlParams();
  const [isEnabled, setIsEnabled] = useState(false);
  const allTags = useAppSelector(getAllTags);

  useEffect(() => {
    if (allTags?.length) {
      setIsEnabled(
        getProcessingRedirect(allTags) === ProcessingRedirectOptions.DE_OATH || !urlPartner
      );
    }
  }, [allTags]);

  return isEnabled;
};

export const useOnCheckoutLoginClick = () => {
  const { handleUpdateCredentials } = useRedirectAfterLogin();

  return (candidates: CandidateType[], donationAmount: number) => {
    saveDonationSelections(candidates, donationAmount);
    const { pathname, queryParams } = getDonationSelectionsObj();
    handleUpdateCredentials(`${pathname}?${queryParams}`);
  };
};
