import axios from 'axios';
import { Dispatch } from 'redux';
import RECIPIENTS_TYPES, { getRecipientProps } from './types';
import { ENV_CONFIG } from 'config/environment';
import API_ROUTES from 'services/apiRoutes';

const headers = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
};

export const getRecipientsAPI = async ({
  amount,
  tags,
  search_str,
  query_str,
  is_public_searchable,
}: getRecipientProps) => {
  const url = `${ENV_CONFIG().API.BASE_URL}${API_ROUTES.GET_RECIPIENTS}`;
  const params = {
    amount,
    tags,
    search_str,
    query_str,
    is_public_searchable,
  };
  try {
    const response = await axios.get(url, {
      headers: { ...headers, 'X-API-Key': process.env.REACT_APP_API_KEY },
      params,
    });
    return Promise.resolve(response);
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const getRecipients =
  ({ amount, tags, search_str, query_str, is_public_searchable }: getRecipientProps) =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch(RECIPIENTS_TYPES.recipientsStart());

    try {
      const response = await getRecipientsAPI({
        amount,
        tags,
        search_str,
        query_str,
        is_public_searchable,
      });
      dispatch(RECIPIENTS_TYPES.recipientsSuccess(response.data));
      return Promise.resolve(response);
    } catch (error: any) {
      dispatch(RECIPIENTS_TYPES.recipientsError(error.message));
      return Promise.reject(error);
    }
  };
