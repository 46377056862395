import theme from 'config/theme';
import { ENV_CONFIG } from 'config/environment';
import { Row } from 'components/Row';
import Column from 'components/Column';
import Spacing from 'components/Spacing';
import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import Icon, { IconNames } from 'components/Icon';
import { ButtonBrand } from 'components/ButtonBrand';
import { Body, Eyebrow, H4 } from 'components/Typography';
import {
  BottomContainer,
  Container,
  Content,
  Label,
  RowContent,
  StyledImage,
  StyledRow,
  Subtitle,
  Title,
  TopContainer,
} from './styled';
import { getPathWithRef } from 'helpers/utils';

export const ImpactReportScreen = () => {
  return (
    <>
      <Header hasIssues />
      <TopContainer>
        <Content>
          <Title>Oath 2024 Annual Impact Report</Title>
          <Spacing $size={16} />
          <H4 $color={theme.colors.white}>
            In 2024, Oath analyzed over 2000 races & directed more than $37,000,000 to the races
            with the highest Impact Scores.
          </H4>
        </Content>
      </TopContainer>
      <Container>
        <Content $centered>
          <H4 $color={theme.colors.white}>
            Oath empowers donors to give more strategically by making data-driven recommendations
            about where dollars have maximum impact. The Oath model is designed to help candidates
            in close elections with high stakes who are currently underfunded. Through this model,
            more than 60% of donations went to races decided by less than 5% - meaning every dollar
            mattered.
          </H4>
        </Content>
        <Spacing $size={60} />
        <StyledRow $justifyContent="space-between" $alignItems="center" $isReverse>
          <RowContent>
            <Subtitle>What We Accomplished in 2024</Subtitle>
            <Spacing $size={20} />
            <H4 $color={theme.colors.white}>
              Despite hard losses at the top of the ticket, we achieved meaningful and important
              wins this year - and we couldn’t have done it without the tens of thousands of Oath
              donors who believe that there is a smarter way to make an impact.
            </H4>
            <Spacing $size={20} />
            <Row $justifyContent="space-between">
              <Column $width="45%">
                <Subtitle $panelFont>$37 Million</Subtitle>
                <Body $color={theme.colors.white}>Total amount raised in 2024</Body>
              </Column>
              <Column $width="45%">
                <Subtitle $panelFont>751</Subtitle>
                <Body $color={theme.colors.white}>Total number of candidates supported</Body>
              </Column>
            </Row>
            <Spacing $size={20} />
            <Row $justifyContent="space-between">
              <Column $width="50%">
                <Subtitle $panelFont>39%</Subtitle>
                <Body $color={theme.colors.white}>Of candidates were elected</Body>
              </Column>
              <Column $width="45%">
                <Subtitle $panelFont>4.4%</Subtitle>
                <Body $color={theme.colors.white}>Average margin across all races</Body>
              </Column>
            </Row>
            <Spacing $size={20} />
            <Row $justifyContent="space-between">
              <Column $width="50%">
                <Subtitle $panelFont>67%</Subtitle>
                <Body $color={theme.colors.white}>{'Of races were decided by <5%'}</Body>
              </Column>
            </Row>
          </RowContent>
          <StyledImage
            $end
            alt="Impact Report 1"
            src={`${ENV_CONFIG().CLOUD_STORAGE.URL}/impact_report/1.png`}
          />
        </StyledRow>
        <Spacing $size={60} />
        <StyledRow $alignItems="center">
          <StyledImage
            alt="Impact Report 2"
            src={`${ENV_CONFIG().CLOUD_STORAGE.URL}/impact_report/2.png`}
          />
          <RowContent>
            <Subtitle>Oath’s Impact</Subtitle>
            <Spacing $size={20} />
            <H4 $color={theme.colors.white}>
              Due to the support of the Oath community, hundreds of Democrats & Independents across
              the country were elected from school board to US Senate. Many of these races were
              decided by razor thin margins where every dollar mattered to turn out the vote at the
              end.
            </H4>
            <Spacing $size={20} />
            <Row $justifyContent="space-between">
              <Column $width="45%">
                <Subtitle $panelFont>29</Subtitle>
                <Body $color={theme.colors.white}>Total amount seats flipped</Body>
              </Column>
              <Column $width="50%">
                <Subtitle $panelFont>264</Subtitle>
                <Body $color={theme.colors.white}>Total amount seats held</Body>
              </Column>
            </Row>
            <Spacing $size={20} />
            <Row $justifyContent="space-between">
              <Column $width="45%">
                <Subtitle $panelFont>217</Subtitle>
                <Body $color={theme.colors.white}>
                  # of Votes that Steve Doyle (WI Assembly 94) won by
                </Body>
              </Column>
              <Column $width="50%">
                <Subtitle $panelFont>357</Subtitle>
                <Body $color={theme.colors.white}>{'Races decided by <10%'}</Body>
              </Column>
            </Row>
          </RowContent>
        </StyledRow>
        <Spacing $size={60} />
        <StyledRow $justifyContent="space-between" $isReverse $alignItems="flex-start">
          <RowContent>
            <Subtitle>The Breakdown</Subtitle>
            <Spacing $size={20} />
            <H4 $color={theme.colors.white}>
              {
                'Oath continued to put money where it really mattered this cycle, with over 90% of dollars being directed to races decided by <10% margin.'
              }
            </H4>
            <Spacing $size={20} />
            <Eyebrow $color={theme.colors.white}>TARGETED INVESTMENTS</Eyebrow>
            <Spacing $size={20} />
            <H4 $color={theme.colors.white}>
              {
                'The Oath community strategically invested in over 350 races that were decided by <10% margin. Notably, as of 11/18, there are still 32 targeted races too close to call including Allison Riggs in NC Supreme Court, Marcy Kaptur in US House OH-09, and Stephanie Simacek in AZ HD 2, where over $1.5 million in donations could have made the difference in these races.'
              }
            </H4>
          </RowContent>
          <RowContent $fitSize>
            <StyledRow $fitSize>
              <Content $alignContent="flex-start" $gap="8px">
                <Row $width="max-content" $alignItems="center">
                  <Label>91.1%</Label>
                  <Spacing $size={8} $isVertical />
                  <Body $color={theme.colors.white}>{'decided by <10%'}</Body>
                </Row>
                <Spacing $size={8} />
                <Row $width="max-content" $alignItems="center">
                  <Label>84.1%</Label>
                  <Spacing $size={8} $isVertical />
                  <Body $color={theme.colors.white}>{'decided by <7.5%'}</Body>
                </Row>
                <Spacing $size={8} />
                <Row $width="max-content" $alignItems="center">
                  <Label>66.8%</Label>
                  <Spacing $size={8} $isVertical />
                  <Body $color={theme.colors.white}>{'decided by <5%'}</Body>
                </Row>
                <Spacing $size={8} />
                <Row $width="max-content" $alignItems="center">
                  <Label>42.4%</Label>
                  <Spacing $size={8} $isVertical />
                  <Body $color={theme.colors.white}>{'decided by <2.5%'}</Body>
                </Row>
                <Spacing $size={8} />
                <Row $width="max-content" $alignItems="center">
                  <Label>11.9%</Label>
                  <Spacing $size={8} $isVertical />
                  <Body $color={theme.colors.white}>{'decided by <1%'}</Body>
                </Row>
              </Content>
              <StyledImage
                $hideOnMobile
                alt="Impact Report 3"
                src={`${ENV_CONFIG().CLOUD_STORAGE.URL}/impact_report/3.png`}
              />
            </StyledRow>
          </RowContent>
        </StyledRow>
        <Spacing $size={60} />
        <StyledRow $alignItems="center">
          <StyledImage
            alt="Impact Report 4"
            src={`${ENV_CONFIG().CLOUD_STORAGE.URL}/impact_report/4.png`}
          />
          <RowContent>
            <Subtitle>2024 Election Highlights</Subtitle>
            <Spacing $size={20} />
            <H4 $color={theme.colors.white}>
              As a community, we achieved many of the goals we set out to accomplish by giving
              smarter.
            </H4>
            <Spacing $size={20} />
            <Eyebrow $color={theme.colors.white}>SOME HIGHLIGHTS:</Eyebrow>
            <Spacing $size={8} />
            <Row $justifyContent="space-between">
              <Column $width="35%">
                <Subtitle $panelFont>25,000</Subtitle>
                <Body $color={theme.colors.white}>Donations made to PA House races</Body>
              </Column>
              <Column $width="20%" $alignContent="center">
                <Icon name={IconNames.ArrowRight} color={theme.colors.white} size={40} />
              </Column>
              <Column $width="35%" $alignContent="center">
                <Body $color={theme.colors.white}>
                  Democrats held the chamber by
                  <br />
                  <Body $color={theme.colors.white} $fontType="italic">
                    less than 1000 votes
                  </Body>
                </Body>
              </Column>
            </Row>
            <Spacing $size={20} />
            <Row $justifyContent="space-between">
              <Column $width="35%">
                <Subtitle $panelFont>$500K</Subtitle>
                <Body $color={theme.colors.white}>Raised for Janelle Bynum, OR-05</Body>
              </Column>
              <Column $width="20%" $alignContent="center">
                <Icon name={IconNames.ArrowRight} color={theme.colors.white} size={40} />
              </Column>
              <Column $width="35%" $alignContent="center">
                <Body $color={theme.colors.white}>
                  Janelle Bynum flipped the seat by
                  <br />
                  <Body $color={theme.colors.white} $fontType="italic">
                    less than 2.5%
                  </Body>
                </Body>
              </Column>
            </Row>
            <Spacing $size={20} />
            <Row $justifyContent="space-between">
              <Column $width="35%">
                <Subtitle $panelFont>80%</Subtitle>
                <Body $color={theme.colors.white}>Funding boost to WI AD-89</Body>
              </Column>
              <Column $width="20%" $alignContent="center">
                <Icon name={IconNames.ArrowRight} color={theme.colors.white} size={40} />
              </Column>
              <Column $width="35%" $alignContent="center">
                <Body $color={theme.colors.white}>
                  This race won by just{' '}
                  <Body $color={theme.colors.white} $fontType="italic">
                    827 votes!
                  </Body>
                </Body>
              </Column>
            </Row>
            <Spacing $size={20} />
            <H4 $color={theme.colors.white}>
              Our relentless focus at Oath is helping you engage in elections in the most strategic
              and sustainable way and we aren’t stopping any time soon.
            </H4>
          </RowContent>
        </StyledRow>
      </Container>
      <BottomContainer>
        <Content $centered>
          <Subtitle>Take a Look at Your Impact</Subtitle>
          <Spacing $size={16} />
          <H4 $color={theme.colors.white}>
            Curious about what impact you made this year? Sign in to see your personalized Impact
            Report.
          </H4>
          <Spacing $size={16} />
          <H4 $color={theme.colors.white}>
            If you don’t have an Oath account, sign up now to get a personalized Impact Report next
            year.
          </H4>
          <Spacing $size={24} />
          <StyledRow>
            <ButtonBrand to={getPathWithRef('/profile')} $secondary>
              Sign in to your account
            </ButtonBrand>
            <ButtonBrand to={getPathWithRef('/login')} $secondary>
              Sign up for next year
            </ButtonBrand>
          </StyledRow>
        </Content>
      </BottomContainer>
      <Footer />
    </>
  );
};
