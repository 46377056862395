import { FC } from 'react';
import Slider from 'rc-slider';
import theme from 'config/theme';
import { Row } from 'components/Row';
import Column from 'components/Column';
import Spacing from 'components/Spacing';
import { UtilityText } from 'components/Typography';
import { HvpTag } from 'screens/Summary/HvpTag';
import { CandidateDetailsColumn, AlignTop, NoImpactScore } from './styled';

const getFinancialsDate = dateStr => {
  const apiDate = new Date(dateStr);
  const todaysDate = new Date();

  // add two days to apiDate
  apiDate.setDate(apiDate.getDate() + 2);
  const date = apiDate < todaysDate ? apiDate : todaysDate;
  return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
};

interface Props {
  competitiveness?: string;
  electionGoal?: string;
  financialsUpdatedAt?: string;
  fundraisingTarget: string;
  impactScore?: string;
  raised: string;
}

const sliderStyles = {
  rail: {
    height: 12,
    borderRadius: 12,
  },
  track: {
    background: theme.colors.darkGreen,
    height: 12,
  },
  handle: {
    display: 'none',
  },
};

export const Details: FC<Props> = ({
  competitiveness,
  electionGoal,
  financialsUpdatedAt,
  fundraisingTarget,
  impactScore,
  raised,
}) => {
  const amountRaised = Math.round((parseInt(raised) / parseInt(fundraisingTarget)) * 100);

  return (
    <>
      {!impactScore ? (
        <NoImpactScore>
          <UtilityText>
            Impact Score not available for this recipient. Oath's Impact Score does not currently
            score Organizations or hyper local races where sufficient data is not available.
          </UtilityText>
        </NoImpactScore>
      ) : (
        <CandidateDetailsColumn $noWrap $alignContent="space-between">
          {competitiveness && (
            <>
              <UtilityText>Competitiveness:</UtilityText>
              <HvpTag text={competitiveness} />
            </>
          )}
          {electionGoal && (
            <>
              <UtilityText>Seat Status:</UtilityText>
              <HvpTag text={electionGoal[0].toUpperCase() + electionGoal.toLowerCase().slice(1)} />
            </>
          )}
          {!isNaN(amountRaised) && (
            <>
              <AlignTop>
                <UtilityText>Amount Raised:</UtilityText>
              </AlignTop>
              <AlignTop>
                <Column>
                  <Row>
                    <UtilityText>{amountRaised > 200 ? '+200' : amountRaised}%</UtilityText>
                    <Spacing $isVertical $size={8} />
                    <Slider
                      min={0}
                      max={100}
                      value={amountRaised}
                      styles={sliderStyles}
                      className="slider-card"
                    />
                  </Row>
                  {financialsUpdatedAt && (
                    <UtilityText $color={theme.colors.gray}>
                      Financials as of {getFinancialsDate(financialsUpdatedAt)}
                    </UtilityText>
                  )}
                </Column>
              </AlignTop>
            </>
          )}
          {/* {item?.campaign_website && (
                    <UtilityText>
                      Learn more on {item?.recipient}'s{' '}
                      <Link href={item.campaign_website} target="_blank" $fontSize="inherit">
                        Campaign Website
                      </Link>
                    </UtilityText>
                  )} */}
        </CandidateDetailsColumn>
      )}
    </>
  );
};
