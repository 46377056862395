import { useState } from 'react';
import theme from 'config/theme';
import Spacing from 'components/Spacing';
import { CustomTable } from 'components/CustomTable';
import { localizedDate } from 'helpers/date';
import { useAppSelector } from 'helpers/hooks';
import { getUserData } from 'selectors/user';
import { getTransactionsData, getTransactionsSummary } from 'selectors/transactions';
import {
  BigText,
  RowContainer,
  LeftContent,
  RightContent,
  SmallText,
  DonationHistoryShowMoreButton,
} from './styled';
import { formatNumberAsCurrency } from 'helpers/utils';
import { Row } from 'components/Row';
// import { Link } from 'components/Link';
import { RecipientSummaryReportModal } from './RecipientSummaryReportModal';

const DEFAULT_ROW_COUNT = 4;

const columns = [
  {
    key: 'transaction_date',
    name: 'Date',
    formatFn: date => {
      const dateObj = new Date(date);
      return dateObj.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    },
    width: '33.33%',
  },
  {
    key: 'recipient',
    name: 'Recipient',
  },
  {
    key: 'page_name',
    name: 'Page',
  },
  {
    key: 'amount',
    name: 'Amount Donated',
    formatFn: amount => formatNumberAsCurrency(amount, { trailingZeroDisplay: 'auto' }),
  },
];

const columnsMobile = [
  {
    key: 'transaction_date',
    name: 'Date',
    formatFn: date => {
      const dateObj = new Date(date);
      return dateObj.toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'numeric',
        year: '2-digit',
      });
    },
    width: '15%',
  },
  {
    key: 'recipient',
    name: 'Recipient',
  },
  {
    key: 'amount',
    name: 'Amount',
    formatFn: amount => formatNumberAsCurrency(amount, { trailingZeroDisplay: 'auto' }),
  },
];

export const DonationHistory = () => {
  const [visibleRowCount, setVisibleRowCount] = useState(DEFAULT_ROW_COUNT);
  const [isReportEmailModalOpen, setIsReportEmailModalOpen] = useState(false);
  const { userData, summary, transactions } = useAppSelector(state => ({
    userData: getUserData(state),
    summary: getTransactionsSummary(state),
    transactions: getTransactionsData(state),
  }));

  const onShowMoreClick = () => {
    setVisibleRowCount(rowCount => rowCount + 10);
  };

  const onShowLessClick = () => {
    setVisibleRowCount(DEFAULT_ROW_COUNT);
  };

  const getSinceDate = () => {
    const rawDate = summary?.min_donation_date || userData?.created_at;
    return localizedDate(rawDate);
  };

  // const onDownloadButtonClick = () => {
  //   setIsReportEmailModalOpen(true);
  // };

  return (
    <>
      <RecipientSummaryReportModal
        isOpen={isReportEmailModalOpen}
        handleCloseModal={() => {
          setIsReportEmailModalOpen(false);
        }}
      />
      {/* <RowContainer $justifyContent="flex-end"> // hiding for now with recipient table updates
        <Link onClick={onDownloadButtonClick} $color={theme.colors.inkBlue}>
          NEW! Download your race-level results here.
        </Link>
      </RowContainer> */}
      <Spacing $size={4} />
      <RowContainer>
        <LeftContent $width={'50%'}>
          <BigText>{summary?.total_donations || '0'} Donations</BigText>
          <SmallText color={theme.shadows.black(0.5)}>Since {getSinceDate()}</SmallText>
        </LeftContent>
        <RightContent $width={'50%'}>
          <BigText>{summary?.total_candidates || '-'}</BigText>
          <SmallText color={theme.shadows.black(0.5)}>Total candidates supported</SmallText>
        </RightContent>
      </RowContainer>
      <Spacing $size={16} />
      {transactions?.length > 0 ? (
        <>
          <CustomTable
            hideForSmall={true}
            columns={columns}
            data={transactions.slice(0, visibleRowCount)}
          />
          <CustomTable
            hideForLarge={true}
            columns={columnsMobile}
            data={transactions.slice(0, visibleRowCount)}
          />
          <Row style={{ gap: '8px' }}>
            {transactions?.length && visibleRowCount < transactions.length && (
              <DonationHistoryShowMoreButton onClick={onShowMoreClick}>
                Show more
              </DonationHistoryShowMoreButton>
            )}
            {transactions?.length && visibleRowCount > DEFAULT_ROW_COUNT && (
              <DonationHistoryShowMoreButton onClick={onShowLessClick}>
                Show less
              </DonationHistoryShowMoreButton>
            )}
          </Row>
        </>
      ) : (
        <SmallText>Details will appear here after you make your first donation.</SmallText>
      )}
    </>
  );
};
