import { useEffect } from 'react';
import theme from 'config/theme';
import { Link } from 'components/Link';
import Spacing from 'components/Spacing';
import { Footer } from 'components/Footer';
import { Bar } from 'components/Header/Bar';
import { BodySmall, Eyebrow } from 'components/Typography';
import { SuggestedIssueCard } from 'components/Cards/SuggestedIssueCard';
import { trackPixel } from 'helpers/pixel';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { useAuth } from 'hooks/auth';
import { getRecommendationsState } from 'selectors/recommendations';
import { getUserProfile } from 'store/user/actions';
import { getRecommendations } from 'store/recommendations/actions';
import { Container, Description, RowComponent, Title } from './styled';
import { getPathWithRef } from 'helpers/utils';

export const RecommendationsScreen = () => {
  const dispatch = useAppDispatch();
  const auth0Requested = useAuth();
  const { recommendations } = useAppSelector(state => ({
    recommendations: getRecommendationsState(state),
  }));

  useEffect(() => {
    if (auth0Requested) {
      dispatch(getRecommendations());
      dispatch(getUserProfile());
    }
  }, [auth0Requested]);

  useEffect(() => {
    trackPixel('universalPixel');
  }, []);

  return (
    <>
      <Bar />
      <Container $textAlign="center" $justifyItems="center">
        <Eyebrow $color={theme.colors.inkBlue}>ACCOUNT CREATED</Eyebrow>
        <Spacing $size={40} />
        <Title>Thank you for joining Oath!</Title>
        <Description>
          Keep the momentum going and make your first donation today. Based on your
          <br /> responses, here are our top recommendations.
        </Description>
        <Spacing $size={40} />
        <RowComponent $justifyContent="space-between">
          {recommendations.data?.map((item, index) => (
            <div key={index}>
              <SuggestedIssueCard
                title={item.value}
                query_str={item.query_str}
                description={item.tag_cta || item.desc}
              />
            </div>
          ))}
        </RowComponent>
        <Spacing $size={32} />
        <Link to={getPathWithRef('/profile')} style={{ textAlign: 'center', color: 'inherit' }}>
          <BodySmall $color={theme.colors.shaft}>Take me to my profile</BodySmall>
        </Link>
      </Container>
      <Footer />
    </>
  );
};
