import { useEffect, useState } from 'react';
import theme from 'config/theme';
import { ENV_CONFIG } from 'config/environment';
import Spacing from 'components/Spacing';
import { RowToGrid } from 'components/Row';
import { Body } from 'components/Typography';
import { ButtonBrand } from 'components/ButtonBrand';
import { useAppSelector } from 'helpers/hooks';
import { getDonateConfig } from 'selectors/siteConfig';
import { ModalComponent } from '.';
import { StyledImage, TextContainer, Title } from './styled';
import { getPathWithRef } from 'helpers/utils';

export const DonateModal = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [modalMaxWidth, setModalMaxWidth] = useState(650);
  const donateModalConfig = useAppSelector(state => getDonateConfig(state, 'donate_modal'));

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 800) {
        setModalMaxWidth(350);
      } else {
        setModalMaxWidth(650);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClick = () => {
    setIsOpen(false);
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      closeModal={() => setIsOpen(false)}
      style={{
        width: '-webkit-fill-available',
        height: 'fit-content',
        maxWidth: modalMaxWidth,
        border: 'none',
        margin: '20px auto',
        justifySelf: 'center',
      }}
      contentLabel="Modal donate"
      iconColor={theme.colors.black}
    >
      <RowToGrid $switchToGrid={800}>
        <StyledImage
          alt="blue alert"
          src={`${
            ENV_CONFIG().CLOUD_STORAGE.URL
          }/static_content/${donateModalConfig?.donate_modal_image}`}
        />
        <TextContainer $verticalPadding="40px">
          <Title>{donateModalConfig?.donate_modal_header}</Title>
          <Spacing $size={8} />
          <Body>{donateModalConfig?.donate_modal_desc}</Body>
          <Spacing $size={24} />
          <ButtonBrand
            onClick={handleClick}
            href={getPathWithRef(donateModalConfig?.donate_modal_link)}
            $backgroundColor={theme.colors.oathBlue}
          >
            {donateModalConfig?.donate_modal_button}
          </ButtonBrand>
        </TextContainer>
      </RowToGrid>
    </ModalComponent>
  );
};
