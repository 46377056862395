import { Body, Eyebrow, H4 } from 'components/Typography';
import { ButtonBrand } from 'components/ButtonBrand';
import theme from 'config/theme';
import { GradientBorderCard } from 'components/Cards/GradientBorderCard';
import Spacing from 'components/Spacing';
import { useEffect, useState } from 'react';
import { getFundingGapAPI } from 'store/funding_gap/actions';
import { CardPadding, CountdownHR, CountdownLinkRow } from './styled';
import { Counter } from './Counter';
import { getPathWithRef } from 'helpers/utils';

const STARTING_GAP = 64100000;

export const DollarCountCard = () => {
  const [gapAmountEstimate, setGapAmountEstimate] = useState<number | null>(null);
  const [isGapUpdated, setIsGapUpdated] = useState(false);
  const [smallCounterStarted, setSmallCounterStarted] = useState(false);
  const [bigCounterStarted, setBigCounterStarted] = useState(false);

  useEffect(() => {
    getFundingGapAPI()
      .then(response => {
        setGapAmountEstimate(parseFloat(response.data.current_gap_amount_estimate));
        setIsGapUpdated(true);
      })
      .catch(() => {
        setGapAmountEstimate(null);
      });
  }, []);

  const decreaseAmount = (maxAmount: number, interval: number, minGapAmount = 0) => {
    setTimeout(() => {
      const random = Math.floor(Math.random() * maxAmount) + 1;
      if (!gapAmountEstimate || gapAmountEstimate <= minGapAmount) {
        return;
      }
      setGapAmountEstimate(amount =>
        amount ? (amount - random > minGapAmount ? amount - random : minGapAmount) : null
      );
      decreaseAmount(maxAmount, interval, minGapAmount);
    }, interval);
  };

  useEffect(() => {
    if (!isGapUpdated) {
      return;
    }
    if (!smallCounterStarted) {
      setSmallCounterStarted(true);
      decreaseAmount(10, 5000);
    }
    if (!bigCounterStarted) {
      setBigCounterStarted(true);
      decreaseAmount(20, 15000);
    }
  }, [isGapUpdated]);

  return (
    <GradientBorderCard borderType="top" gradient={theme.gradients.electricToDarkGradient({})}>
      <Spacing $size={17} />
      <CardPadding>
        <Eyebrow $color={theme.colors.inkBlue}>Join the blue wave</Eyebrow>
        <>
          <Spacing $size={17} />
          <Counter
            duration={smallCounterStarted ? 2 : 10}
            from={STARTING_GAP}
            number={gapAmountEstimate || STARTING_GAP}
          />
          <Spacing $size={17} />
          <H4>is needed to close the gap for key downballot races</H4>
        </>
      </CardPadding>
      <CountdownHR />
      <CardPadding>
        <Body>
          Harris-Walz have already raised a historic $600+ million, while Democrats downballot are
          being outraised in races that cost <b>as little as $50K</b>. Make your dollar go further
          by supporting these competitive downballot races.
        </Body>
        <Spacing $size={17} />
        <CountdownLinkRow $justifyContent="space-between">
          <ButtonBrand
            to={getPathWithRef('/set?p=top-issues')}
            $backgroundColor={theme.colors.oathBlue}
          >
            Support by Issue
          </ButtonBrand>
          <ButtonBrand
            to={getPathWithRef('/set?p=states')}
            $backgroundColor={theme.colors.oathBlue}
          >
            Support by State
          </ButtonBrand>
        </CountdownLinkRow>
      </CardPadding>
      <Spacing $size={17} />
    </GradientBorderCard>
  );
};
