import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/create-store';
import { IRecipient, RecipientsState } from 'store/recipients/types';

export const getRecipientsState = (state: RootState): RecipientsState => state.recipients;

export const getRecipientsData = createSelector(
  [getRecipientsState],
  (state: RecipientsState): IRecipient[] => state.data?.recipients
);
