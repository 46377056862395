import { FC, Fragment, useEffect, useState } from 'react';
import { useField, useForm } from 'react-final-form';
import { datadogLogs } from '@datadog/browser-logs';
import { RadioField } from 'components/FinalForm/RadioField';
import Spacing from 'components/Spacing';
import { Body, BodySmall, UtilityText } from 'components/Typography';
import {
  checkoutLogFirebaseEvent,
  formatCcExpiration,
  getDEPayRequestBody,
  getIsCcNumberAmex,
  getIsCheckoutMonthly,
  getIsDataValid,
  getNewAchToken,
  getNewCcToken,
  getPaymentErrorFieldName,
  getValidateFn,
  paymentErrorsDefault,
  paymentErrorsDefaultAch,
  paymentErrorsDefaultCC,
  usePaymentOptions,
  validateAccountNumber,
  validateCVV,
  validateCVVAmex,
  validateCcExp,
  validateCcNumber,
  validateRoutingNumber,
} from './utils';
import theme from 'config/theme';
import {
  Checkbox,
  DetailsColumn,
  DetailsRow,
  HR,
  IconCircle,
  PaymentCheckboxRow,
  PaymentStatusRow,
  PaymentTypesRow,
  PaymentsColumn,
  ToggleGroup,
} from '../styled';
import { Link } from 'components/Link';
import { Row } from 'components/Row';
import { ToggleField } from 'components/FinalForm/ToggleField';
import { CandidateType } from 'components/Slate/Candidates/Candidates';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { getUserState } from 'selectors/user';
import { TextFieldWithFloatingLabel } from 'components/FinalForm/TextFieldWithFloatingLabel';
import { postDEDonationWithToken } from 'store/democracyengine/actions';
import classNames from 'classnames';
import Icon, { IconNames } from 'components/Icon';
import { LoginCTA } from './LoginCTA';
import { AxiosError } from 'axios';
import { REQUEST_AUTH_TOKEN_ERROR, TOKEN_AUTH_INCOMPLETE } from 'store/democracyengine/constants';
import { getPathWithRef, noop } from 'helpers/utils';
import { RetryPaymentButton } from './RetryPaymentButton';

const radioLabelColor = theme.colors.black;

function retryAsyncFunction<T>(
  asyncFunction: () => Promise<T>,
  maxRetries = 3,
  interval = 5000
): Promise<T> {
  let attempts = 0;

  return new Promise((resolve, reject) => {
    const executeFunction = async () => {
      attempts++;
      try {
        const result = await asyncFunction();
        resolve(result);
      } catch (error) {
        if (error !== TOKEN_AUTH_INCOMPLETE || attempts >= maxRetries) {
          reject(error);
        } else {
          setTimeout(executeFunction, interval);
        }
      }
    };

    executeFunction();
  });
}

interface PaymentTokenError {
  errorMessageFull: string;
  errorStr: string;
}

interface Props {
  candidates: CandidateType[];
  democracyEngineCheckoutUrl: string;
  donationAmount: number;
  setTokensToSave: (values: any) => void;
}

export const Payment: FC<Props> = ({
  candidates,
  democracyEngineCheckoutUrl,
  donationAmount,
  setTokensToSave,
}) => {
  const form = useForm();
  const dispatch = useAppDispatch();
  const { ccPayments, achPayments } = usePaymentOptions();
  const paymentTypeField = useField('payment_type');
  const paymentTypeValue = paymentTypeField.input.value;
  const userState = useAppSelector(getUserState);
  const isCheckoutV2Enabled = true;
  const tokenField = useField('default_token').input.value;
  const [newCreditCardToken, setNewCreditCardToken] = useState(
    getNewCcToken(tokenField, paymentTypeValue, ccPayments)
  );
  const [newAchToken, setNewAchToken] = useState(
    getNewAchToken(tokenField, paymentTypeValue, achPayments)
  );
  const ccNumberField = useField('cc_number').input.value;
  const ccExpField = useField('cc_exp').input.value;
  const cvvField = useField('cc_verification_value').input.value;
  const achAccountTypeField = useField('ach_account_type').input.value;
  const achAccountField = useField('ach_account_number').input.value;
  const achRoutingField = useField('ach_routing_number').input.value;
  const [isCcTokenLoading, setIsCcTokenLoading] = useState(false);
  const [isAchTokenLoading, setIsAchTokenLoading] = useState(false);
  const [isCcTokenVerified, setIsCcTokenVerified] = useState(newCreditCardToken !== 'cc_token');
  const [isAchTokenVerified, setIsAchTokenVerified] = useState(newAchToken !== 'ach_token');
  const [isSaveCcChecked, setIsSaveCcChecked] = useState(true);
  const [isSaveAchChecked, setIsSaveAchChecked] = useState(true);
  const [paymentErrors, setPaymentErrors] = useState(paymentErrorsDefault);

  const updateCcToken = async () => {
    setIsCcTokenLoading(true);
    try {
      await createPaymentToken((token: string) => {
        setNewCreditCardToken(token);
        setIsCcTokenVerified(true);
        setPaymentErrors(currentPaymentErrors => ({
          ...currentPaymentErrors,
          ...paymentErrorsDefaultCC,
        }));
        form.change('cc_verification_value', getIsCcNumberAmex(ccNumberField) ? '****' : '***');
        form.change(
          'cc_number',
          `***********${!getIsCcNumberAmex(ccNumberField) ? '*' : ''}${ccNumberField.slice(-4)}`
        );
      });
    } catch (error) {
      const { errorMessageFull, errorStr } = error as PaymentTokenError;
      setPaymentErrors(errors => ({
        ...errors,
        ccForm: errorMessageFull,
        [getPaymentErrorFieldName(errorStr)]: 'Error',
      }));
    }
    setIsCcTokenLoading(false);
  };

  const verifyCcPayment = () => {
    if (ccNumberField.startsWith('***********') && cvvField.startsWith('***')) {
      return;
    }
    setIsCcTokenVerified(false);
    setPaymentErrors(currentPaymentErrors => ({
      ...currentPaymentErrors,
      ...paymentErrorsDefaultCC,
    }));
    const isDataValid = getIsDataValid(form.getState().hasValidationErrors, form.getState().errors);
    if (
      tokenField === newCreditCardToken &&
      isDataValid &&
      !cvvField.includes('*') &&
      !ccNumberField.includes('*') &&
      isCheckoutV2Enabled
    ) {
      updateCcToken();
    }
  };

  const updateAchToken = async () => {
    setIsAchTokenLoading(true);
    try {
      await createPaymentToken((token: string) => {
        setNewAchToken(token);
        setIsAchTokenVerified(true);
        setPaymentErrors(currentPaymentErrors => ({
          ...currentPaymentErrors,
          ...paymentErrorsDefaultAch,
        }));
        form.change('ach_routing_number', '*********');
        form.change('ach_account_number', `************${achAccountField.slice(-4)}`);
      });
    } catch (error) {
      const { errorMessageFull, errorStr } = error as PaymentTokenError;
      setPaymentErrors(errors => ({
        ...errors,
        achForm: errorMessageFull,
        [getPaymentErrorFieldName(errorStr)]: 'Error',
      }));
    }
    setIsAchTokenLoading(false);
  };

  const verifyAchPayment = () => {
    if (achAccountField.slice(0, 12) === '************' && achRoutingField === '*********') {
      return;
    }
    if (achAccountField === achRoutingField && !!achAccountField) {
      setPaymentErrors(errors => ({
        ...errors,
        achForm: 'Account number and routing number cannot be the same.',
      }));
      return;
    }
    setIsAchTokenVerified(false);
    setPaymentErrors(currentPaymentErrors => ({
      ...currentPaymentErrors,
      ...paymentErrorsDefaultAch,
    }));
    const isDataValid = getIsDataValid(form.getState().hasValidationErrors, form.getState().errors);
    if (
      tokenField === newAchToken &&
      isDataValid &&
      achAccountTypeField &&
      !achAccountField.includes('*') &&
      !achRoutingField.includes('*') &&
      isCheckoutV2Enabled
    ) {
      updateAchToken();
    }
  };

  useEffect(() => {
    setNewCreditCardToken(getNewCcToken(tokenField, paymentTypeValue, ccPayments));
    setNewAchToken(getNewAchToken(tokenField, paymentTypeValue, achPayments));
  }, [tokenField]);

  useEffect(() => {
    checkoutLogFirebaseEvent({
      eventName: 'view_step_payment',
      userId: userState?.data?.id || '',
      candidates,
      donationAmount,
    });
  }, []);

  useEffect(() => {
    if ((isSaveCcChecked || getIsCheckoutMonthly()) && isCcTokenVerified) {
      setTokensToSave(tokens => [...tokens, newCreditCardToken]);
    } else {
      setTokensToSave([]);
    }
  }, [isSaveCcChecked, newCreditCardToken, isCcTokenVerified]);

  useEffect(() => {
    if ((isSaveAchChecked || getIsCheckoutMonthly()) && isAchTokenVerified) {
      setTokensToSave(tokens => [...tokens, newAchToken]);
    } else {
      setTokensToSave([]);
    }
  }, [isSaveAchChecked, newAchToken, isAchTokenVerified]);

  useEffect(() => {
    verifyCcPayment();
  }, [ccNumberField, ccExpField, cvvField]);

  useEffect(() => {
    verifyAchPayment();
  }, [achAccountField, achRoutingField, achAccountTypeField]);

  const paymentTokenCallback = async () => {
    return dispatch(postDEDonationWithToken(getDEPayRequestBody(form.getState().values)));
  };

  const createPaymentToken = async (callback: (token: string) => void) => {
    return new Promise((resolve, reject) => {
      retryAsyncFunction(paymentTokenCallback)
        .then(response => {
          callback(response.data.token);
          form.change('default_token', response.data.token);
          resolve(response);
        })
        .catch(error => {
          const isCreditCard = form.getState().values.payment_type === 'credit_card';
          let errorMessageFull = '';
          let errorStr = '';
          if (error === TOKEN_AUTH_INCOMPLETE) {
            datadogLogs.logger.info(
              `Token auth incomplete in browser caught in Payment checkout step. User email: ${
                form.getState().values.email
              }, payment type: ${isCreditCard ? 'credit card' : 'ach'}`
            );
            errorMessageFull = `Sorry, but we were unable to validate your payment method at this time. ${
              isCreditCard
                ? 'Please confirm that all details are correct and try again.'
                : 'Your bank may require further verification. Please try a different payment method or reach out to team@oath.vote for support.'
            }`;
          } else if (error === REQUEST_AUTH_TOKEN_ERROR) {
            errorMessageFull =
              'Sorry! We\'re experiencing high volume right now. Please click "Try Again" in a few seconds.';
          } else {
            const axiosError = error as AxiosError;
            const errorData = axiosError?.response?.data;
            errorStr =
              errorData && !Array.isArray(errorData)
                ? errorData['detail'] || ''
                : (axiosError?.response?.data || [[]])[0][1] || '';
            errorMessageFull = `Sorry, there was an error verifying your ${
              isCreditCard ? 'credit card' : 'account'
            }. Please review your information and click "Try Again". `;
            try {
              errorMessageFull += axiosError?.response?.data
                ? (axiosError?.response?.data as Array<[string, string]>)
                    .map(err => err[1])
                    .join('. ') + '.'
                : '';
            } catch (error) {
              noop();
            }
          }
          reject({ errorMessageFull, errorStr } as PaymentTokenError);
        });
    });
  };

  useEffect(() => {
    if (paymentTypeValue === 'ach' && !achPayments?.length) {
      form.change('default_token', newAchToken);
      return;
    }
    if (paymentTypeValue === 'credit_card' && !ccPayments?.length) {
      form.change('default_token', newCreditCardToken);
      return;
    }
  }, [paymentTypeValue]);

  const onSaveCreditCardCheckboxClick = () => {
    setIsSaveCcChecked(isChecked => !isChecked);
  };

  const onSaveAchCheckboxClick = () => {
    setIsSaveAchChecked(isChecked => !isChecked);
  };

  return (
    <>
      <Body>Select Payment Method:</Body>
      <Spacing $size={5} />
      {isCheckoutV2Enabled || (ccPayments.length > 0 && achPayments.length > 0) ? (
        <>
          <PaymentTypesRow>
            <ToggleGroup>
              <ToggleField name="payment_type" value="credit_card">
                <BodySmall $color="inherit">Credit Card</BodySmall>
              </ToggleField>
              <ToggleField name="payment_type" value="ach">
                <BodySmall $color="inherit">ACH Bank Account</BodySmall>
              </ToggleField>
            </ToggleGroup>
          </PaymentTypesRow>
          <HR />
        </>
      ) : (
        <Spacing $size={12} />
      )}
      {paymentTypeValue === 'credit_card' ? (
        <div>
          <PaymentsColumn>
            {ccPayments.map(ccPayment => (
              <RadioField
                key={ccPayment.id}
                name="default_token"
                value={ccPayment.payment_authorization_token}
              >
                <BodySmall $color={radioLabelColor}>Credit Card ({ccPayment.last_4})</BodySmall>
              </RadioField>
            ))}
          </PaymentsColumn>
          {isCheckoutV2Enabled && (
            <>
              <div className={classNames({ 'display-none': ccPayments.length <= 0 })}>
                <Spacing $size={7} />
                <RadioField name="default_token" value={newCreditCardToken}>
                  <BodySmall $color={radioLabelColor}>Add New Credit Card</BodySmall>
                </RadioField>
                <Spacing $size={8} />
              </div>
              {(tokenField === newCreditCardToken || !ccPayments?.length) && (
                <>
                  <DetailsColumn>
                    <TextFieldWithFloatingLabel
                      name="cc_number"
                      label="Credit Card Number"
                      maxlength={16}
                      validate={getValidateFn(validateCcNumber, paymentErrors['cc_number'])}
                      key={paymentErrors['cc_number'] ? 'cc_number_0' : 'cc_number_1'}
                    />
                    {!userState?.data?.email && (
                      <LoginCTA candidates={candidates} donationAmount={donationAmount} />
                    )}
                    <DetailsRow>
                      <TextFieldWithFloatingLabel
                        name="cc_exp"
                        format={formatCcExpiration}
                        label="Expiration MM/YY"
                        maxlength={5}
                        validate={getValidateFn(validateCcExp, paymentErrors['cc_exp'])}
                        key={paymentErrors['cc_exp'] ? 'cc_exp_0' : 'cc_exp_1'}
                      />
                      <TextFieldWithFloatingLabel
                        name="cc_verification_value"
                        label="CVV"
                        maxlength={4}
                        validate={getValidateFn(
                          getIsCcNumberAmex(ccNumberField) ? validateCVVAmex : validateCVV,
                          paymentErrors['cc_verification_value']
                        )}
                        key={paymentErrors['cc_verification_value'] ? 'ccv_0' : 'ccv_1'}
                      />
                    </DetailsRow>
                  </DetailsColumn>
                  <UtilityText $color={theme.shadows.black(0.6)}>
                    *All fields are required unless otherwise noted.
                  </UtilityText>
                  {isCcTokenLoading && (
                    <PaymentStatusRow>
                      <BodySmall>Verifying credit card...</BodySmall>
                    </PaymentStatusRow>
                  )}
                  {isCcTokenVerified && (
                    <PaymentStatusRow>
                      <IconCircle
                        $color="successGreen"
                        $justifyContent="space-around"
                        $alignItems="center"
                      >
                        <Icon name={IconNames.Checkmark} color={theme.colors.white} size={10} />
                      </IconCircle>
                      <BodySmall>Credit card successfully verified</BodySmall>
                    </PaymentStatusRow>
                  )}
                  {paymentErrors.ccForm && !isCcTokenLoading && (
                    <PaymentStatusRow>
                      <IconCircle
                        $color="errorRed"
                        $justifyContent="space-around"
                        $alignItems="center"
                      >
                        <Icon name={IconNames.XClose} color={theme.colors.white} size={15} />
                      </IconCircle>
                      <BodySmall $color={theme.colors.errorRed}>{paymentErrors.ccForm}</BodySmall>
                    </PaymentStatusRow>
                  )}
                  {getIsCheckoutMonthly() ? (
                    <PaymentCheckboxRow>
                      <BodySmall $color={theme.colors.shaft}>
                        This credit card will be charged each month for your recurring contribution.
                      </BodySmall>
                    </PaymentCheckboxRow>
                  ) : (
                    !!userState?.data?.id && (
                      <PaymentCheckboxRow
                        role="button"
                        onClick={onSaveCreditCardCheckboxClick}
                        $alignItems="center"
                        $pressable={true}
                      >
                        <Checkbox
                          $isChecked={isSaveCcChecked}
                          $justifyContent="space-around"
                          $alignItems="center"
                        >
                          {isSaveCcChecked && (
                            <Icon name={IconNames.Checkmark} color={theme.colors.white} size={10} />
                          )}
                        </Checkbox>
                        <BodySmall $color={theme.colors.shaft}>
                          Save credit card for future use
                        </BodySmall>
                      </PaymentCheckboxRow>
                    )
                  )}
                </>
              )}
            </>
          )}
        </div>
      ) : (
        <div>
          <PaymentsColumn>
            {achPayments.map(achPayment => (
              <RadioField
                key={achPayment.id}
                name="default_token"
                value={achPayment.payment_authorization_token}
              >
                <BodySmall $color={radioLabelColor}>Bank Account ({achPayment.last_4})</BodySmall>
              </RadioField>
            ))}
          </PaymentsColumn>
          {isCheckoutV2Enabled && (
            <>
              <div className={classNames({ 'display-none': achPayments.length <= 0 })}>
                <Spacing $size={7} />
                <RadioField name="default_token" value={newAchToken}>
                  <BodySmall $color={radioLabelColor}>Add New ACH</BodySmall>
                </RadioField>
                <Spacing $size={7} />
              </div>
              {achPayments.length <= 0 && <Body>Account Type:</Body>}
              {(tokenField === newAchToken || !achPayments?.length) && (
                <>
                  <DetailsColumn>
                    <DetailsRow $justifyContent="flex-start" $gap="100px">
                      <RadioField name="ach_account_type" value="checking">
                        <BodySmall $color={radioLabelColor}>Checking</BodySmall>
                      </RadioField>
                      <RadioField name="ach_account_type" value="savings">
                        <BodySmall $color={radioLabelColor}>Savings</BodySmall>
                      </RadioField>
                    </DetailsRow>
                    <TextFieldWithFloatingLabel
                      name="ach_account_number"
                      label="Account Number"
                      validate={getValidateFn(validateAccountNumber, paymentErrors['ach'])}
                      key={paymentErrors['ach'] ? 'ach_account_number_0' : 'ach_account_number_1'}
                    />
                    {!userState?.data?.email && (
                      <LoginCTA candidates={candidates} donationAmount={donationAmount} />
                    )}
                    <TextFieldWithFloatingLabel
                      name="ach_routing_number"
                      label="Routing Number"
                      validate={getValidateFn(validateRoutingNumber, paymentErrors['ach'])}
                      key={paymentErrors['ach'] ? 'ach_routing_number_0' : 'ach_routing_number_1'}
                    />
                  </DetailsColumn>
                  <UtilityText $color={theme.shadows.black(0.6)}>
                    *All fields are required unless otherwise noted.
                  </UtilityText>
                  {isAchTokenLoading && (
                    <PaymentStatusRow>
                      <BodySmall>Verifying bank account...</BodySmall>
                    </PaymentStatusRow>
                  )}
                  {isAchTokenVerified && (
                    <PaymentStatusRow>
                      <IconCircle
                        $color="successGreen"
                        $justifyContent="space-around"
                        $alignItems="center"
                      >
                        <Icon name={IconNames.Checkmark} color={theme.colors.white} size={10} />
                      </IconCircle>
                      <BodySmall>Bank account successfully verified</BodySmall>
                    </PaymentStatusRow>
                  )}
                  {paymentErrors.achForm && !isAchTokenLoading && (
                    <PaymentStatusRow>
                      <IconCircle
                        $color="errorRed"
                        $justifyContent="space-around"
                        $alignItems="center"
                      >
                        <Icon name={IconNames.XClose} color={theme.colors.white} size={15} />
                      </IconCircle>
                      <BodySmall $color={theme.colors.errorRed}>{paymentErrors.achForm}</BodySmall>
                    </PaymentStatusRow>
                  )}
                  {getIsCheckoutMonthly() ? (
                    <PaymentCheckboxRow>
                      <BodySmall $color={theme.colors.shaft}>
                        This account will be charged each month for your recurring contribution.
                      </BodySmall>
                    </PaymentCheckboxRow>
                  ) : (
                    !!userState?.data?.id && (
                      <PaymentCheckboxRow
                        role="button"
                        onClick={onSaveAchCheckboxClick}
                        $alignItems="center"
                        $pressable={true}
                      >
                        <Checkbox
                          $isChecked={isSaveAchChecked}
                          $justifyContent="space-around"
                          $alignItems="center"
                        >
                          {isSaveAchChecked && (
                            <Icon name={IconNames.Checkmark} color={theme.colors.white} size={10} />
                          )}
                        </Checkbox>
                        <BodySmall $color={theme.colors.shaft}>
                          Save ACH account for future use
                        </BodySmall>
                      </PaymentCheckboxRow>
                    )
                  )}
                </>
              )}
            </>
          )}
        </div>
      )}
      {!isCheckoutV2Enabled && (
        <>
          <Spacing $size={10} />
          <Row $justifyContent="space-around">
            <Link href={getPathWithRef(democracyEngineCheckoutUrl)} $color={theme.colors.shaft}>
              <BodySmall $color={theme.colors.shaft}>Use New Payment</BodySmall>
            </Link>
          </Row>
        </>
      )}
      <Spacing $size={24} />
      <RetryPaymentButton
        hasError={
          paymentTypeValue === 'credit_card' ? !!paymentErrors.ccForm : !!paymentErrors.achForm
        }
        isVerified={paymentTypeValue === 'credit_card' ? isCcTokenVerified : isAchTokenVerified}
        onClick={paymentTypeValue === 'credit_card' ? updateCcToken : updateAchToken}
        submitting={paymentTypeValue === 'credit_card' ? isCcTokenLoading : isAchTokenLoading}
      />
    </>
  );
};
