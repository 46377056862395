import theme from 'config/theme';
import { Row } from 'components/Row';
import { Link } from 'components/Link';
import Spacing from 'components/Spacing';
import { BodySmall } from 'components/Typography';
import { SettingsCard } from 'components/Cards/SettingsCard';
import { getObjectDisplayName, formatNumberAsCurrency, getPathWithRef } from 'helpers/utils';
import { useAppSelector } from 'helpers/hooks';
import { getUserState } from 'selectors/user';
import { IEmailCadence, emailCadenceDictionary } from './constants';
import { BigText, Issue, SettingBlock, SmallText } from './styled';

export const Settings = ({ topIssues }) => {
  const { userState } = useAppSelector(state => ({
    userState: getUserState(state),
  }));

  const EditRow = ({ text }: { text: string }) => (
    <Row $justifyContent="space-between">
      <SmallText>{text}</SmallText>
    </Row>
  );

  return (
    <SettingsCard>
      <SettingBlock>
        <EditRow text="Your 2024 Oath Budget" />
        <BigText>
          {formatNumberAsCurrency(userState?.data?.monthly_budget || 0, {
            trailingZeroDisplay: 'auto',
          })}{' '}
          every month
        </BigText>
      </SettingBlock>
      <SettingBlock>
        <EditRow text="Your Top Issues" />
        <Spacing $size={15} />
        {topIssues?.map(issue => (
          <Issue key={issue}>
            <SmallText>{issue}</SmallText>
          </Issue>
        ))}
        {!topIssues?.length && '-'}
      </SettingBlock>
      <SettingBlock>
        <EditRow text="Your Recommendation Cadence" />
        <BigText>
          {getObjectDisplayName<IEmailCadence>(
            userState?.data?.email_cadence,
            emailCadenceDictionary
          )}
        </BigText>
      </SettingBlock>
      <Link to={getPathWithRef('edit')} style={{ textAlign: 'center', color: 'inherit' }}>
        <BodySmall $color={theme.colors.shaft}>Edit</BodySmall>
      </Link>
    </SettingsCard>
  );
};
