import { FC, useState } from 'react';
import { CandidateType } from '../Candidates/Candidates';
import ButtonLink from 'components/ButtonLink';
import { PopupModal } from 'components/PopupModal';

interface Props {
  candidates: CandidateType[];
}

export const CustomLinkCopy: FC<Props> = ({ candidates }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const getCustomLink = () => {
    const recipientsStr = candidates
      .filter(can => can.isSelected)
      .map(can => `${can.misc_ids?.ext_de_url_id}:${can.donationAmount}`)
      .join(';');
    const urlObj = new URL(window.location.href);
    urlObj.searchParams.set('recipients', recipientsStr);
    return decodeURIComponent(urlObj.toString().replace('donate', 'checkout'));
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(getCustomLink());
    setIsPopupOpen(true);
    setTimeout(() => setIsPopupOpen(false), 2000);
  };

  return (
    <>
      <PopupModal autoClose={true} isSelected={isPopupOpen} title="Copied to Clipboard" />
      <ButtonLink onClick={handleCopy}>Copy Checkout Link</ButtonLink>
    </>
  );
};
