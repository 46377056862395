import { FC } from 'react';
import theme from 'config/theme';
import { ENV_CONFIG } from 'config/environment';
import { Row } from 'components/Row';
import Spacing from 'components/Spacing';
import { BodySmall, H3 } from 'components/Typography';
import { ButtonBrand } from 'components/ButtonBrand';
import { getPathWithRef, getUrlParams, isKeyInObject } from 'helpers/utils';
import { tabIssues } from 'screens/Monthly/constants';
import { Content, Description, Header, Container } from './styled';

interface Props {
  title: string;
  description: string;
  query_str: string;
}

export const SuggestedIssueCard: FC<Props> = ({ description, query_str, title }) => {
  const { urlRef } = getUrlParams();
  const ref_code = urlRef ? `&ref=${urlRef}-FD` : '&ref=FD';

  return (
    <Container>
      <Header>
        <H3 $color={theme.colors.white}>{title}</H3>
      </Header>
      <Content>
        <Description dangerouslySetInnerHTML={{ __html: description }} />
        <Spacing $size={16} />
        <Row $justifyContent="space-between">
          <ButtonBrand
            to={`${ENV_CONFIG().SITE_URL}?p=${query_str}${ref_code}`}
            $backgroundColor={theme.colors.inkBlue}
            style={{ flex: 1 }}
          >
            <BodySmall $color={theme.colors.white}>DONATE ONE TIME</BodySmall>
          </ButtonBrand>
          {isKeyInObject(query_str, tabIssues) && (
            <>
              <Spacing $isVertical $size={8} />
              <ButtonBrand
                href={getPathWithRef(`/monthly?issue=${query_str}`)}
                target="_blank"
                $secondary
                $hasBorder
              >
                DONATE MONTHLY
              </ButtonBrand>
            </>
          )}
        </Row>
      </Content>
    </Container>
  );
};
