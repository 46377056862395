import { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import Loader from 'components/Loader';
import { Slate } from 'components/Slate';
import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import { createSessionID, getUrlParams, scrollToTop } from 'helpers/utils';
import logFirebaseEvent from 'helpers/logFirebaseEvent';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { getTagsState } from 'selectors/tags';
import { getRecipientsState } from 'selectors/recipients';
import {
  clearAccessToken,
  clearDonationSelections,
  getSessionID,
  setSessionID,
} from 'services/storage';
import { getTags, getTagsFullList } from 'store/tags/actions';
import { getRecipients } from 'store/recipients/actions';
import { DesktopLeftContainer } from './DesktopLeftContainer';
import { MainContainer } from './styled';
import { ProcessingRedirect, TagPageType } from 'store/tags/types';
import { ProcessingRedirectOptions } from 'store/tags/constants';
import { CandidateType } from 'components/Slate/Candidates/Candidates';
import { useIsOathCheckoutEnabled, useOathCheckout } from 'hooks/donate';
import {
  democracyEngineCheckoutRedirect,
  engageLabsCheckoutRedirect,
  getCandidates,
  getCandidatesByImpactScore,
  getCandidatesWithNewAmounts,
  getDonationSelectionsObj,
  getProcessingRedirect,
  getSelectedCandidates,
  logFirebaseEventDonateIntent,
  validateDonation,
} from 'helpers/donate';
import Error from 'components/Error';
import { Body, H3 } from 'components/Typography';
import { getUserState } from 'selectors/user';
import { getUserProfile } from 'store/user/actions';
import { useAuth } from 'hooks/auth';
import { DonateModal } from 'components/Modal/DonateModal';
import { PausedModal } from 'components/Modal/PausedModal';
import { getDonateConfig } from 'selectors/siteConfig';

const MAX_RECIPIENTS = 200;
const MIN_RECIPIENTS = 6;

export const MainScreen = () => {
  const dispatch = useAppDispatch();
  const [refWidthValue, setRefWidthValue] = useState(0);
  const { urlTags, urlRef, urlPartner } = getUrlParams();
  const [processingRedirect, setProcessingRedirect] = useState<ProcessingRedirect>(
    ProcessingRedirectOptions.DE_GENERAL
  );
  const [pageType, setPageType] = useState<TagPageType | undefined>();
  const isOathCheckoutEnabled = useIsOathCheckoutEnabled();
  const { recipients, tagsState, userState, donatModalConfig } = useAppSelector(state => ({
    recipients: getRecipientsState(state),
    tagsState: getTagsState(state),
    userState: getUserState(state),
    donatModalConfig: getDonateConfig(state, 'donate_modal'),
  }));
  const [selectedDonationAmount, setSelectedDonationAmount] = useState<number | undefined>();
  const [candidates, setCandidates] = useState<CandidateType[]>([]);
  const [showCustomSplit, setShowCustomSplit] = useState(false);
  const oathCheckout = useOathCheckout();
  const allTags = tagsState.data?.tags;
  const tagsFullList = tagsState.fullListData?.tags;

  useAuth(() => dispatch(getUserProfile()));

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    if (userState.error) clearAccessToken();
  }, [userState]);

  useEffect(() => {
    if (!allTags) {
      dispatch(getTags({ urlPartner }));
      dispatch(getTagsFullList());
      clearDonationSelections();
    }
    if (!recipients.data) {
      dispatch(
        getRecipients({
          amount: urlPartner ? MAX_RECIPIENTS : MIN_RECIPIENTS,
          query_str: urlPartner,
          is_public_searchable: !urlPartner,
        })
      );
    }
    if (!getSessionID()) {
      setSessionID(createSessionID(10));
    }
    logFirebaseEvent('land_visitor', {
      timestamp: new Date().toString(),
      ref: urlRef,
      tags: urlTags,
      partner: urlPartner,
      session_id: getSessionID(),
    });
  }, []);

  useEffect(() => {
    const { candidates: savedCandidates, total: savedDonationAmount } = getDonationSelectionsObj();
    if (savedDonationAmount) {
      setSelectedDonationAmount(Number(savedDonationAmount));
    }
    if (recipients?.data?.recipients && !urlPartner && !savedCandidates) {
      setCandidates(getCandidatesByImpactScore(recipients.data.recipients));
    } else if (recipients?.data?.recipients && !candidates.length) {
      setCandidates(getCandidates(recipients.data.recipients, savedCandidates));
    }
  }, [recipients]);

  useEffect(() => {
    if (allTags?.length) {
      const updatedProcessingRedirect = getProcessingRedirect(allTags);
      const updatedPageType =
        allTags?.findIndex(tag => tag.page_type === 'LIST') >= 0 ? 'LIST' : allTags[0].page_type;
      setProcessingRedirect(updatedProcessingRedirect);
      setPageType(updatedPageType);
    }
  }, [allTags]);

  const onCustomSplit = (isCustomSplit: boolean) => {
    setShowCustomSplit(isCustomSplit);
  };

  const onUserDonationTotalChange = (amount?: number) => {
    setSelectedDonationAmount(amount);
    setCandidates(getCandidatesWithNewAmounts(amount, candidates, onCustomSplit));
  };

  const onSelectCandidates = (updatedCandidates: CandidateType[]) => {
    setCandidates(
      getCandidatesWithNewAmounts(selectedDonationAmount, updatedCandidates, onCustomSplit)
    );
  };

  const onDonateClick = (selectedTags?: string[]) => {
    const selectedCandidates = getSelectedCandidates(candidates);
    const isDonationValid = validateDonation(selectedCandidates, selectedDonationAmount);
    if (!isDonationValid || !selectedDonationAmount) {
      return;
    }
    logFirebaseEventDonateIntent(selectedDonationAmount, candidates, selectedTags);
    if (isOathCheckoutEnabled) {
      oathCheckout(selectedCandidates, selectedDonationAmount, selectedTags);
    } else if (
      processingRedirect === ProcessingRedirectOptions.DE_GENERAL ||
      processingRedirect === ProcessingRedirectOptions.DE_OATH
    ) {
      const queryStrList = tagsState.data.tags
        .filter(tag => selectedTags?.includes(tag.value))
        .map(tag => tag.query_str);
      democracyEngineCheckoutRedirect(selectedCandidates, selectedDonationAmount, '', queryStrList);
    } else if (processingRedirect === ProcessingRedirectOptions.EN_GENERAL) {
      engageLabsCheckoutRedirect(selectedCandidates, selectedDonationAmount);
    }
  };

  const isLoadingTags = !allTags;
  const isMissingTags = !allTags?.length;
  const hasApiError = tagsState.error || recipients.error;

  return (
    <>
      {(!allTags?.length || !urlPartner || (allTags?.length > 0 && !allTags[0].whitelabeled)) && (
        <Header urlPartner={urlPartner} />
      )}
      {!hasApiError && isLoadingTags ? (
        <div className="loader-center">
          <Loader width={60} height={60} />
        </div>
      ) : (
        <MainContainer>
          {hasApiError || isMissingTags ? (
            <Error
              data={{ component: 'Main' }}
              errorMsg="API failure on donate page"
              shouldAlert={!!hasApiError}
            >
              <H3>
                {hasApiError ? 'An error has occured. Contact us.' : 'Donate page data not found.'}
              </H3>
            </Error>
          ) : (
            <>
              {urlPartner && allTags[0].status === 'PAUSED' ? (
                <PausedModal paused_misc={allTags[0].paused_misc} />
              ) : (
                allTags[0].status === 'ACTIVE' &&
                allTags[0].owner === 'OATH' &&
                !donatModalConfig?.donate_modal_link.includes(`p=${urlPartner}`) &&
                donatModalConfig?.donate_modal_config === 'on' && <DonateModal />
              )}
              {pageType !== 'MEDIA' && <DesktopLeftContainer setRefWidthValue={setRefWidthValue} />}
              <ErrorBoundary
                fallback={
                  <Error
                    data={{ component: 'Slate' }}
                    errorMsg="Failure with donate Slate component"
                    shouldAlert={true}
                  >
                    <Body>We encountered an error. Please contact support.</Body>
                  </Error>
                }
              >
                <Slate
                  allTags={allTags}
                  tagsFullList={tagsFullList}
                  recipients={recipients?.data?.recipients}
                  candidates={candidates}
                  onSelectCandidates={onSelectCandidates}
                  selectedDonationAmount={selectedDonationAmount}
                  onDonateClick={onDonateClick}
                  onUserDonationTotalChange={onUserDonationTotalChange}
                  setCandidates={setCandidates}
                  setSelectedDonationAmount={setSelectedDonationAmount}
                  showCustomSplit={showCustomSplit}
                  refWidthValue={refWidthValue}
                  type={pageType || 'LIST'}
                />
              </ErrorBoundary>
            </>
          )}
        </MainContainer>
      )}
      <Footer />
    </>
  );
};
