import axios from 'axios';
import { ENV_CONFIG } from 'config/environment';
import API_ROUTES from 'services/apiRoutes';
import { getSiteAccessToken } from 'services/storage';

type ReportType = 'RECIPIENT_SUMMARY';

const headers = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
};

export const postReportEmailQueue = async (report_type: ReportType, body?: object) => {
  const url = `${ENV_CONFIG().API.BASE_URL}${API_ROUTES.REPORT_EMAIL_QUEUE(report_type)}`;

  try {
    const response = await axios.post(url, body, {
      headers: { ...headers, Authorization: `Bearer ${getSiteAccessToken()}` },
    });
    return Promise.resolve(response);
  } catch (error: any) {
    let errorMessage = 'There was an error requesting your report.';
    if (error.response?.status === 400) {
      errorMessage += ` ${(error.response?.data as string).split(':').shift()}.`;
    } else {
      errorMessage += ' Please contact support.';
    }
    return Promise.reject(errorMessage);
  }
};
