import theme from 'config/theme';
import Column from 'components/Column';
import Spacing from 'components/Spacing';
import { ButtonBrand } from 'components/ButtonBrand';
import { BodySmall, Eyebrow, H4 } from 'components/Typography';
import { GradientBorderCard } from 'components/Cards/GradientBorderCard';
import { WhiteHeader, ButtonsContainer, Title, CounterContainer, CounterNumber } from './styled';
import { getPathWithRef } from 'helpers/utils';

export const DaysCountCard = ({ date }) => {
  const today = new Date().getTime();
  const electionDay = new Date(date).getTime();
  const daysUntilElection = Math.ceil((electionDay - today) / (1000 * 60 * 60 * 24));

  return (
    <GradientBorderCard borderType="top" gradient={theme.gradients.electricToDarkGradient({})}>
      <WhiteHeader $justifyContent="center" $alignItems="center">
        <CounterContainer>
          <CounterNumber>{daysUntilElection}</CounterNumber>
        </CounterContainer>
        <Title>day{daysUntilElection !== 1 && 's'} until Election Day</Title>
      </WhiteHeader>
      <ButtonsContainer $textAlign="center">
        <Eyebrow>Oath’s recommendations</Eyebrow>
        <H4>
          With only days until the election, here are some highly strategic ways to be involved:
        </H4>
        <ButtonBrand
          to={getPathWithRef('/donate?p=final-push')}
          $backgroundColor={theme.colors.oathBlue}
        >
          DONATE TO FINAL PUSH
        </ButtonBrand>
        <Column $textAlign="center">
          <BodySmall>And make a plan to vote!</BodySmall>
          <Spacing $size={4} />
          <ButtonBrand
            to="https://www.ballotready.org/"
            target="_blank"
            $backgroundColor={theme.colors.oathBlue}
          >
            go to ballotready
          </ButtonBrand>
        </Column>
      </ButtonsContainer>
    </GradientBorderCard>
  );
};
