import { ButtonBrand } from 'components/ButtonBrand';
import { ModalComponent } from 'components/Modal';
import { Body, Eyebrow, UtilityText } from 'components/Typography';
import theme from 'config/theme';
import { FC, useState } from 'react';
import { CancelModalContent, SubmitButton } from './styled';
import Spacing from 'components/Spacing';
import classNames from 'classnames';
import { postReportEmailQueue } from 'store/report_email_queue/actions';

interface Props {
  isOpen: boolean;
  handleCloseModal: () => void;
}

export const RecipientSummaryReportModal: FC<Props> = ({ isOpen, handleCloseModal }) => {
  const [isRequestSuccessful, setIsRequestSuccessful] = useState(false);
  const [requestError, setRequestError] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  const sendReportRequest = () => {
    setIsProcessing(true);
    postReportEmailQueue('RECIPIENT_SUMMARY', { year: 2024 })
      .then(() => {
        setIsRequestSuccessful(true);
        setIsProcessing(false);
      })
      .catch((errorMessage: string) => {
        setRequestError(errorMessage);
        setIsProcessing(false);
      });
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      closeModal={handleCloseModal}
      contentLabel="Send recipient summary report email modal"
      style={{ maxWidth: '325px' }}
    >
      <CancelModalContent>
        {isRequestSuccessful ? (
          <>
            <Eyebrow $color={theme.colors.inkBlue}>Report Request Submitted</Eyebrow>
            <Spacing $size={2} />
            <Body>
              You will receive an email with an attached report within the next 5 minutes.
            </Body>
            <Spacing $size={13} />
            <ButtonBrand
              $backgroundColor={theme.colors.inkBlue}
              $width="100%"
              onClick={handleCloseModal}
            >
              Close
            </ButtonBrand>
          </>
        ) : (
          <>
            <Eyebrow $color={theme.colors.inkBlue}>Request Email Report</Eyebrow>
            <Spacing $size={2} />
            <Body>
              This report will be emailed to you. Please confirm that you would like to continue.
            </Body>
            <Spacing $size={13} />
            <SubmitButton
              $backgroundColor={theme.colors.inkBlue}
              $width="100%"
              onClick={sendReportRequest}
              className={classNames({ 'animated-background': isProcessing })}
              disabled={isProcessing}
              $submitting={isProcessing}
            >
              {isProcessing ? 'Sending Report' : 'Send My Report'}
            </SubmitButton>
            {requestError && (
              <>
                <Spacing $size={5} />
                <UtilityText $color={theme.colors.errorRed}>{requestError}</UtilityText>
              </>
            )}
          </>
        )}
      </CancelModalContent>
    </ModalComponent>
  );
};
