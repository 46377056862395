import { FC, useEffect, useState } from 'react';
import theme from 'config/theme';
import { Row } from 'components/Row';
import ButtonLink from 'components/ButtonLink';
import { ModalComponent } from 'components/Modal';
import Icon, { IconNames } from 'components/Icon';
import { CandidateImage } from './CandidateImage';
import {
  CandidateEdit,
  CandidatesEditGrid,
  Checkmark,
  DonateButton,
  FooterContainer,
  Input,
  Label,
  ModalContent,
  ModalFooter,
  ModalSubheader,
  ModalTitle,
  TagIdentifier,
} from '../styled';
import { getIsCandidateTagVisible } from './utils';
import { getUrlParams } from 'helpers/utils';
import { CandidateType } from 'components/Slate/Candidates/Candidates';

interface Props {
  candidates: CandidateType[];
  isOpen: boolean;
  setCandidates: (updatedCandidates: CandidateType[]) => void;
  setIsOpen: (isOpen: boolean) => void;
}

export const EditCandidatesModal: FC<Props> = ({
  isOpen,
  setIsOpen,
  candidates,
  setCandidates,
}) => {
  const { urlPartner } = getUrlParams();
  const [tempCandidates, setTempCandidates] = useState(candidates);

  useEffect(() => {
    setTempCandidates(candidates);
  }, [candidates]);

  const handleCloseModal = () => {
    setTempCandidates(candidates);
    setIsOpen(false);
  };

  const updateSelected = () => {
    setCandidates(tempCandidates);
    handleCloseModal();
  };

  const getOnChange = index => event => {
    const newTempCandidates = [...tempCandidates];
    newTempCandidates[index] = {
      ...newTempCandidates[index],
      isSelected: event.currentTarget.checked,
    };
    setTempCandidates(newTempCandidates);
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      closeModal={handleCloseModal}
      contentLabel="Edit recipients modal"
    >
      <ModalContent>
        <ModalTitle>CUSTOMIZE RECIPIENTS</ModalTitle>
        <ModalSubheader>
          Select or deselect the recipients you want to contribute to:
        </ModalSubheader>
        <CandidatesEditGrid>
          {tempCandidates.map((candidate, index) => (
            <Row key={index} $alignItems="center">
              <Label>
                <Input
                  type="checkbox"
                  id={`select-${candidate.recipient_id}`}
                  name={candidate.recipient_name}
                  defaultChecked={candidate.isSelected}
                  onChange={getOnChange(index)}
                />
                <Checkmark $alignItems="center" $isChecked={candidate.isSelected}>
                  {/* TODO: use new checkmark icons from design https://oath-product.atlassian.net/browse/OATH-181 */}
                  <Icon name={IconNames.Check} size={20} color={theme.colors.white} />
                </Checkmark>
                <CandidateEdit key={candidate.recipient_id} $alignItems="center">
                  <CandidateImage recipientId={candidate.misc_ids?.photo_id} />
                  <div>
                    <div>{candidate.recipient_name}</div>
                    <div>{candidate.race?.election.display_info}</div>
                    {getIsCandidateTagVisible(candidate.recipient_id, urlPartner) && (
                      <TagIdentifier>LGBTQ+</TagIdentifier>
                    )}
                  </div>
                </CandidateEdit>
              </Label>
            </Row>
          ))}
        </CandidatesEditGrid>
      </ModalContent>
      <ModalFooter>
        <FooterContainer>
          <DonateButton onClick={updateSelected}>Update</DonateButton>
          <ButtonLink onClick={handleCloseModal}>Cancel</ButtonLink>
        </FooterContainer>
      </ModalFooter>
    </ModalComponent>
  );
};
